<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex flex-column">
    <h1
      class="bg-custom"
      :class="external ? 'py-1' : ''"
      :style="{
        'background-color': colortsDiscover,
        color: failed ? 'red' : 'white',
        'text-transform': failed ? 'none' : 'uppercase',
        'letter-spacing': failed ? '0' : '0.5em',
      }"
      v-if="creating || failed"
    >
      {{ showDiscover }}
    </h1>
    <template v-if="showSelectReader">
      <v-alert :class="external ? 'my-2' : ''" dense outlined type="warning"
        >Please Select Device:</v-alert
      >
      <v-select
        @change="selectReader"
        v-model="readerSelect"
        :items="discoveredReaders"
        outlined
        dense
        label="Device"
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.ip_address"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.serial_number"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-select>
    </template>
    <v-form
      ref="form"
      v-model="validForm"
      id="create-payment"
      name="create-payment"
    >
      <v-select
        v-model="body.type"
        :rules="[rules.required]"
        label="Type"
        :disabled="creating"
        :items="_types"
        item-text="label"
        item-value="value"
        dense
        outlined
      />

      <template v-if="body.type == 'Stripe'">
        <div class="my-2">
          <patient-card @cardid="addCardId($event)"></patient-card>
        </div>
      </template>
      <template v-if="invalidAmount">
        <v-alert dense outlined type="error">
          The amount must be less than <strong> {{ deb | currency }}</strong>
        </v-alert>
      </template>

      <template v-if="!external">
        <v-text-field
          v-model.number="body.amount"
          :rules="external ? [] : [rules.required, rules.isNumber]"
          label="Amount"
          prepend-inner-icon="mdi-currency-usd"
          @click="clickAmount"
          outlined
          :disabled="creating"
          dense
        />
        <v-text-field
          v-model.trim="body.details"
          :rules="external ? [] : [rules.required]"
          label="Details"
          outlined
          :disabled="creating"
          dense
        />
      </template>
    </v-form>

    <div class="d-flex fill-width">
      {{/* Add buttons here */}}
      <v-btn color="error" width="120px" depressed rounded @click="reset()">
        Cancel
      </v-btn>
      <template v-if="failed">
        <v-btn
          color="orange darken-2"
          class="mx-1"
          :loading="loading"
          width="120px"
          depressed
          dark
          rounded
          @click="discover()"
        >
          Retry
        </v-btn>
      </template>

      <slot></slot>
      <v-spacer />
      <v-btn
        color="primary"
        :loading="loading || creating"
        :disabled="
          !validForm ||
          body.amount == 0 ||
          idcardinValid ||
          invalidAmount ||
          creating
        "
        width="120px"
        depressed
        rounded
        @click="addPayment()"
      >
        {{ btnText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import PatientCard from "@/views/Patients/components/PatientCard.vue";
import { loadStripeTerminal } from "@stripe/terminal-js";

import Vue from "vue";
import rules from "@/components/account/rules";
import {
  notificationError,
  notifyError,
  notifySuccess,
  notifyInfo,
} from "@/components/Notification";
import { getAPI } from "@/api/axios-base";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  components: { PatientCard },
  name: "make-payment",
  props: {
    external: {
      type: Boolean,
      default: () => false,
    },
    nopatient: {
      type: Boolean,
      default: () => false,
    },
    sale: Object,
    procedure: Object,
    deb: Number,
    confirmPay: Object,
  },
  data() {
    return {
      showSelectReader: false,
      readerSelect: null,
      loading: false,
      rules,
      btnText: "Submit",
      confirmate: false,
      //PAGOS EXTERN_CARD
      charging: false,

      discoverStatus: "",
      connectStatus: false,
      discoveredReaders: null,
      serverUrl: process.env.VUE_APP_BASE_URL,
      simulated: process.env.VUE_APP_SIMULATED || false,
      paymentStatus: false,
      token: "",
      payment: null,
      terminal: null,
      paymentIntentId: -1,
      paymentDone: false,
      validForm: false,
      failed: false,
      creating: false,
      discount: 0,
      body: {
        amount: 0,
        details: "",
        type: "CASH",
        cardId: "",
      },

      types: [
        { label: "Cash", value: "CASH" },
        { label: "Card Reader", value: "External_Card_Reader" },
        { label: "Zelle", value: "Zelle" },
        { label: "Card", value: "Stripe" },
        { label: "PayPal", value: "Paypal" },
        { label: "Square", value: "Square" },
        { label: "Alphaeon", value: "Alphaeon" },
        { label: "United Medical Credit", value: "UnitedMedicalCredit" },
        { label: "CareCredit", value: "CareCredit" },
      ],
    };
  },
  watch: {
    confirmPay(val) {
      if (val != null && val != undefined) {
        this.btnText = "Confirm";
        this.confirmate = true;
        this.body = {
          amount: Number(val.payment.amount),
          cardId: "",
          type: val.payment.type,
          details: val.payment.details,
        };
      }
    },
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    _types() {
      if (!this.nopatient) {
        return [
          { label: "Cash", value: "CASH" },
          { label: "Card Reader", value: "External_Card_Reader" },
          { label: "Zelle", value: "Zelle" },
          { label: "Card", value: "Stripe" },
          { label: "PayPal", value: "Paypal" },
          { label: "Square", value: "Square" },
          { label: "Alphaeon", value: "Alphaeon" },
          { label: "United Medical Credit", value: "UnitedMedicalCredit" },
          { label: "CareCredit", value: "CareCredit" },
        ];
      } else {
        return [
          { label: "Cash", value: "CASH" },
          { label: "Card Reader", value: "External_Card_Reader" },
          { label: "Zelle", value: "Zelle" },
          { label: "PayPal", value: "Paypal" },
          { label: "Square", value: "Square" },
          { label: "Alphaeon", value: "Alphaeon" },
          { label: "United Medical Credit", value: "UnitedMedicalCredit" },
          { label: "CareCredit", value: "CareCredit" },
        ];
      }
    },

    idcardinValid() {
      if (this.body.type == "Stripe") {
        if (this.body.cardId != "") {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    invalidAmount() {
      if (this.external) {
        return;
      }
      if (Number(this.body.amount) > this.deb) {
        return true;
      }
      return false;
    },
    showDiscover() {
      return this.discoverStatus;
    },
    colortsDiscover() {
      if (this.discoverStatus.includes("Discovered Readers:")) {
        return "#039BE5";
      }
      if (this.failed == true) {
        return "#FFEBEE";
      }
      switch (this.discoverStatus) {
        case "Discovering readers...":
          return "#A5D6A7";
        case "Waiting for user card":
          return "#FFB74D";
        case "Processing Payment":
          return "#66BB6A";
        case "Payment Ready":
          return "#2E7D32";
        case "Payment Done":
          return "#1B5E20";
        default:
          return "#000000";
      }
    },
  },
  mounted() {
    if (this.external == false) {
      if (this.patientDetail.stripeCards.length != 0) {
        this.mutHaveCard(true);
      } else {
        this.mutHaveCard(false);
      }
    } else {
      this.body.amount = Number(this.sale.totalAmount);
      if (this.sale.patient) {
        this.mutPatientDetails(this.sale.patient);
        if (this.patientDetail.stripeCards.length != 0) {
          this.mutHaveCard(true);
        } else {
          this.mutHaveCard(false);
        }
      } else {
        this.mutHaveCard(false);
      }
    }
  },
  methods: {
    ...mapMutations("crmMedicFormModule", ["mutHaveCard", "mutPatientDetails"]),

    addCardId(value) {
      this.body.cardId = value;
    },

    addPayment() {
      if (this.external) {
        this.loading = true;
        const body = {
          saleId: this.sale.id,
          type: this.body.type,
          cardId: this.body.cardId,
        };
        if (body.cardId == "") {
          delete body.cardId;
        }

        getAPI
          .post("/payment/create-accesories-payment", body)
          .then((res) => {
            if (body.type != "External_Card_Reader") {
              this.loading = false;
              notifySuccess("Payment Complete");
              this.$emit("payment");
              this.$emit("confirmed");
              this.reset();
            } else {
              this.payment = res.data;
              this.charging = true;
              this.creating = true;
              this.discover();
            }
          })
          .catch((error) => {
            this.loading = false;

            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        const body = { ...this.body, procedure: this.procedure.id };

        if (body.cardId == "") {
          delete body.cardId;
        }
        this.loading = true;
        if (this.confirmate) {
          if (body.type == "Stripe") {
            getAPI
              .post("/payment/confirm-payment", {
                paymentUuid: this.confirmPay.payment.uuid,
                cardId: body.cardId,
              })
              .then(() => {
                this.loading = false;
                notifyInfo("Payment Confirmed");
                this.$emit("payment");
                this.$emit("confirmed");
                this.reset();
              })
              .catch((error) => {
                this.loading = false;

                if (error.response?.status == 400) {
                  notifyError(
                    error.response.data.message || error.response.data.details
                  );
                } else {
                  notificationError();
                }
              });
          } else if (body.type == "External_Card_Reader") {
            this.payment = this.confirmPay.payment;
            this.charging = true;
            this.creating = true;
            this.discover();
          }
        } else {
          getAPI
            .post("/patient/createPayment", body)
            .then((res) => {
              if (body.type != "External_Card_Reader") {
                this.loading = false;
                notifyInfo("Payment added");
                this.$emit("payment");
                this.$emit("confirmed");
                this.reset();
              } else {
                this.payment = res.data;
                this.charging = true;
                this.creating = true;
                this.discover();
              }
            })
            .catch((error) => {
              this.loading = false;

              if (error.response?.status == 400) {
                notifyError(
                  error.response.data.message || error.response.data.details
                );
              } else {
                notificationError();
              }
            });
        }
      }
      /******** */
    },
    reset() {
      this.body = { amount: 0, cardId: "", type: "CASH", details: "" };
      this.btnText = "Submit";
      this.confirmate = false;
      this.loading = false;
      this.failed = false;
      this.creating = false;
      this.terminal = null;
      this.charging = false;
      this.showSelectReader = false;
      this.$emit("cancel");
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
        this.$refs.form.reset();
        this.charging = false;
      }
    },

    //External Reader

    unexpectedDisconnect() {
      // In this function, your app should notify the user that the reader disconnected.
      // You can also include a way to attempt to reconnect to a reader.
      console.log("Disconnected from reader");
    },

    async discover() {
      this.creating = true;
      this.failed = false;
      this.discoverStatus = "Discovering readers...";
      const StripeTerminal = await loadStripeTerminal();

      this.terminal = StripeTerminal.create({
        onFetchConnectionToken: this.fetchConnectionToken,
        onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
      });

      await this.discoverReaderHandler();
    },

    async fetchPaymentIntentClientSecret(amount) {
      const amt = amount;
      const responseData = (
        await getAPI.post("/payment/create-payment-intent", {
          amount: amt,
          paymentUuid: String(this.payment.uuid),
        })
      ).data;
      this.paymentIntentId = responseData.id;
      return responseData.client_secret;
    },

    async fetchConnectionToken() {
      const token = (await getAPI.post("/payment/terminal-stripe-token")).data;
      this.token = token;
      return token.secret;
    },
    // Handler for a "Discover readers" button
    async discoverReaderHandler() {
      const config = { simulated: !!this.simulated };

      const connectResult = await this.terminal.discoverReaders(config);

      if (connectResult.error) {
        this.discoverStatus =
          "Failed to discover: " + connectResult.error.message;
        this.failed = true;
        this.creating = false;
        this.loading = false;
      } else if (connectResult.discoveredReaders.length === 0) {
        this.discoverStatus = "No available readers.";
        this.failed = true;
        this.creating = false;
        this.loading = false;
      } else {
        this.discoveredReaders = connectResult.discoveredReaders;
        this.discoverStatus =
          "Discovered Readers: " + connectResult.discoveredReaders.length;
        if (connectResult.discoveredReaders.length == 1) {
          this.connect(connectResult.discoveredReaders[0]);

          this.showSelectReader = false;
        } else {
          this.showSelectReader = true;
        }
      }
    },

    selectReader() {
      this.showSelectReader = false;
      this.connect(this.readerSelect);
    },
    // Handler for a "Connect Reader" button
    async connect(reader) {
      // Just select the first reader here.

      const selectedReader = reader;

      const connectResult = await this.terminal.connectReader(selectedReader);

      if (connectResult.error) {
        this.discoverStatus =
          "Failed to connect: " + connectResult.error.message;
        this.failed = true;
        this.creating = false;
        this.loading = false;
      } else {
        this.discoverStatus =
          "Connected to reader: " + connectResult.reader.label;
        this.connectStatus = true;
        this.creating = false;
        this.collect();
      }
    },

    //Collect
    async collect() {
      this.connectStatus = false;
      this.creating = true;
      this.discoverStatus = "Waiting for user card";

      const amount = this.body.amount * 100;

      const clientSecret = await this.fetchPaymentIntentClientSecret(amount);

      this.terminal.setSimulatorConfiguration({
        testCardNumber: "5555555555554444",
      });

      const result = await this.terminal.collectPaymentMethod(clientSecret);

      if (result.error) {
        this.discoverStatus =
          "Error in card processing: " + result.error.message;
        // Placeholder for handling result.error
        this.creating = false;
        this.failed = true;
        this.loading = false;
      } else {
        this.discoverStatus = "Processing Payment";

        const resultprocessPayment = await this.terminal.processPayment(
          result.paymentIntent
        );

        if (resultprocessPayment.error) {
          this.discoverStatus =
            "Error in process payment: " + resultprocessPayment.error.message;
          this.creating = false;
          this.failed = true;
          this.loading = false;
        } else if (resultprocessPayment.paymentIntent) {
          this.paymentIntentId = resultprocessPayment.paymentIntent.id;

          this.discoverStatus = "Payment Ready";
          this.creating = false;
          this.paymentStatus = true;
          this.captureAsync();
        }
      }
    },

    async captureAsync() {
      this.creating = true;
      await this.capture();
      this.discoverStatus = "Payment Done";
      this.paymentDone = true;
      this.creating = false;
    },
    async capture() {
      this.paymentStatus = false;
      const paymentIntentId = this.paymentIntentId;
      try {
        const responseData = (
          await getAPI.post("/payment/capture-payment-intent", {
            id: paymentIntentId,
          })
        ).data;
        this.loading = false;
        if (this.external) {
          notifySuccess("Payment Complete");
        } else {
          notifyInfo("Payment added");
        }

        this.$emit("payment");
        this.$emit("confirmed");
        this.reset();
      } catch (error) {
        this.loading = false;

        if (error.response?.status == 400) {
          notifyError(
            error.response.data.message || error.response.data.details
          );
        } else {
          notificationError();
        }
      }
    },

    clickAmount() {
      if (this.body.amount == 0) {
        this.body.amount = "";
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.bg-custom {
  margin-top: 2rem;
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;
  background: #26a69a;
  border-radius: 0.25em;
  color: #fff;
  margin: 0 0 1em;
  padding: 0.5em 0;
}
</style>
